<div class="ui grid">
  <div class="row">
    <div class="three wide column"></div>

    <div class="ten wide column">
      <h1 class="ui header">Kategorien</h1>
      <app-topic-cards [topics]="topics" (selectedTopic)="selectTopic($event)"></app-topic-cards>
    </div>
    <div class="three wide column"></div>
  </div>

  <div class="ui divider"></div>

  <div class="row">
    <div class="three wide column"></div>

    <div class="ten wide column">
      <h1 class="ui header">Fachrichtungen</h1>
      <app-topic-cards [topics]="subjectAreas" (selectedTopic)="selectTopic($event, true)"></app-topic-cards>
    </div>
    <div class="three wide column"></div>
  </div>

</div>
