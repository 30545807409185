import {Component, EventEmitter, Input, Output} from '@angular/core';

import {Topic} from '../../../../core/models/topics/topics.model';

@Component({
  selector: 'app-topic-cards',
  templateUrl: './topic-cards.component.html',
  styleUrls: ['./topic-cards.component.css']
})
export class TopicCardsComponent {
  @Input() topics!: Topic[];
  @Output() selectedTopic = new EventEmitter<any>();

  public selectTopic(topic: Topic): void {
    this.selectedTopic.emit(topic);
  }
}
