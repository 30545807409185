<div class="ui four cards">
  <ng-container *ngFor="let topic of topics">
    <div class="ui card">
      <div class="content">
        <div class="header">
          <div class="ui attached button" style="color: #FFFFFF" (click)="selectTopic(topic)"
               (keydown)="selectTopic(topic)" tabindex="-1">
            {{ topic.name }}
            <i class="right chevron icon"></i>
          </div>
        </div>
        <div class="ui divider"></div>
        <div class="description">
          {{ topic.description }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
