import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TopicsComponent} from './topics.component';
import {TopicsRoutingModule} from './topics-routing.module';
import {TopicCardsComponent} from './components/topic-cards/topic-cards.component';


@NgModule({
  declarations: [
    TopicsComponent,
    TopicCardsComponent
  ],
  imports: [
    CommonModule,
    TopicsRoutingModule,
  ]
})
export class TopicsModule { }
