import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';

import {splitTopics} from '../../core/utils/topics.helper';
import {Topic} from '../../core/models/topics/topics.model';
import {TopicsService} from '../../core/services/topics/topics.service';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.css']
})
export class TopicsComponent implements OnInit {
  public topics!: Topic[];
  public subjectAreas!: Topic[];

  constructor(
    private topicsService: TopicsService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.getTopics();
  }

  selectTopic(selectedTopic: Topic, isSubjectArea?: boolean) {
    this.router.navigate(['/articles'], {
      queryParams: isSubjectArea ?
        {subjectAreas: selectedTopic._id} : {topics: selectedTopic._id}
    });
  }

  private getTopics() {
    this.topicsService.getAll().then((res) => {
      const splittedTopics = splitTopics(res.docs);
      this.topics = splittedTopics.topics;
      this.subjectAreas = splittedTopics.subjectAreas;
    });
  }
}
