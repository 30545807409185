import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {TopicsComponent} from './topics.component';
import {AuthGuard} from '../../core/auth/auth-guard';
import {clientRoles} from '../../core/constants/client-roles.config';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    component: TopicsComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [
        clientRoles.IS_USER,
      ]
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TopicsRoutingModule { }
